import React, { useEffect, useState } from "react";
import RegistrazioneAzienda from "components/Vetrina/Registrazione/RegistrazioneAzienda";
import RegistrazionePersona from "components/Vetrina/Registrazione/RegistrazionePersona";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import FooterBB from "components/Vetrina/Footer";
import PngRegistrazione from "../../immagini/ASSETMLJC_ER_03_B.png";
import RegistrazioneCompletata from "components/Vetrina/Registrazione/RegistrazioneCompletata";
import AppBarJob from "components/Vetrina/AppBarJob";
import options from "json/option_configuration.json";
import JobErAutocomplete from "components/JobErAutocomplete";

function Registrazione(props) {
  const [tipoUtente, setTipoUtente] = useState(null);
  const [registrazioneCompletata, setRegistrazioneCompletata] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    setRegistrazioneCompletata(null);
    if (process.env.REACT_APP_AMBIENTE === "modena-reggio") {
      setTipoUtente({
        value: "persona",
        label: "Candidato",
      });
    }
  }, []);

  return (
    <>
      <AppBarJob
        bgcolor={theme.palette.primary.main}
        color={theme.palette.white.main}
        activePage={"Home"}
        onUserLogin={(user) => props.onUserLogin(user)}
      ></AppBarJob>
      <Grid
        container
        backgroundColor={theme.palette.primary.main}
        sx={{
          pl: { xs: 2, md: 15 },
          pr: { xs: 2, md: 15 },
          pb: { xs: 4, md: 8 },
          pt: { xs: 4, md: 8 },
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid size={{ xs: 12, md: 4 }} sx={{ display: { xs: "none", md: "flex" } }}>
          <img alt="registrazione" src={PngRegistrazione} style={{ height: "60vh" }} />
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          {registrazioneCompletata === null && (
            <>
              <Grid container>
                <Grid size={12}>
                  <Typography variant="h1" color={theme.palette.white.main} sx={{ textAlign: "center!important" }} mt={2}>
                    Benvenuto sul portale!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid size={12}>
                  <Typography variant="h5" color={theme.palette.white.main} sx={{ textAlign: "center" }} mt={2}>
                    Registrati per accedere a funzionalità esclusive e contenuti riservati.<br></br>È facile, veloce e gratuito
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" mt={3}>
                <Grid size={{ xs: 12, md: 4 }}>
                  {process.env.REACT_APP_AMBIENTE !== "modena-reggio" && (
                    <JobErAutocomplete
                      color="white"
                      size="small"
                      label="Seleziona tipo registrazione"
                      value={tipoUtente}
                      options={options.candidatoAzienda}
                      onChange={(event, newValue) => {
                        setTipoUtente(newValue);
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {registrazioneCompletata === null && tipoUtente?.value === "azienda" ? (
            <RegistrazioneAzienda onRegistrazioneCompletata={(data) => setRegistrazioneCompletata(data)} />
          ) : registrazioneCompletata === null && tipoUtente?.value === "persona" ? (
            <RegistrazionePersona
              onRegistrazioneCompletata={(data) => {
                setRegistrazioneCompletata(data);
              }}
            />
          ) : registrazioneCompletata !== null ? (
            <RegistrazioneCompletata data={registrazioneCompletata} tipoUtente={tipoUtente?.value} />
          ) : null}
        </Grid>
      </Grid>

      <FooterBB />
    </>
  );
}
export default Registrazione;
