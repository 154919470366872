import { Grid2 as Grid, Typography } from "@mui/material";
import MenuJOB from "../../components/Vetrina/AppBarJob.js";
import JobErButton from "components/JobErButton.js";
import { useNavigate } from "react-router-dom";
import FooterBB from "components/Vetrina/Footer.js";

function ChiSiamo(props) {
  const navigate = useNavigate();

  return (
    <>
      <MenuJOB bgcolor={"primary.main"} color={"white.main"} activePage={"Home"} onUserLogin={(user) => props.onUserLogin(user)}></MenuJOB>
      <Grid spacing={3} container backgroundColor="primary.main" sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 } }}>
        <Grid size={12}>
          <Typography color="white.main" textAlign={"center"} variant="h1" fontWeight={"bold"}>
            Con noi chi cerca trova!
          </Typography>
        </Grid>
        <Grid size={12}>
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <Typography color="white.main" variant="h3">
              MyLapam Job è un punto di riferimento per il mondo del lavoro locale, offrendo un servizio di intermediazione efficace tra domanda e offerta.
              Attraverso la nostra piattaforma di ricerca e selezione del personale, supportiamo aziende e professionisti in cerca di nuove opportunità in tutte
              le aree di Modena e Reggio Emilia, coprendo ogni ruolo necessario per il successo aziendale.
            </Typography>
          ) : (
            <Typography color="white.main" variant="h3">
              Confartigianato Imprese Emilia Romagna è un punto di riferimento per il mondo del lavoro regionale, offrendo un servizio di intermediazione
              efficace tra domanda e offerta. Attraverso la nostra piattaforma di ricerca e selezione del personale, supportiamo aziende e professionisti in
              cerca di nuove opportunità in tutta l’Emilia Romagna, coprendo ogni ruolo necessario per il successo aziendale.
            </Typography>
          )}
        </Grid>
        <Grid size={12}>
          <Typography color="white.main" variant="h3" fontWeight={"bold"}>
            Il nostro obiettivo
          </Typography>
        </Grid>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                E' individuare e proporre candidati altamente qualificati, persone il cui talento e impegno siano in linea con i valori e le esigenze specifiche
                delle imprese che serviamo. Grazie alla collaborazione con i nostri esperti consulenti, adottiamo un processo di selezione accurato e
                personalizzato. Sia che si tratti di tirocinanti, professionisti con esperienza, o figure dirigenziali, lavoriamo per garantire il miglior
                incontro tra candidati e aziende.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                MyLapam Job è impegnata nell'ascolto attivo delle esigenze del territorio e offre soluzioni per chi cerca un primo inserimento, per chi è in
                mobilità o per chi è alla ricerca di nuove sfide. Con passione e professionalità, ci dedichiamo a costruire carriere e a rafforzare le aziende,
                contribuendo allo sviluppo economico e sociale della nostra comunità.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                Scopri come possiamo aiutarti a raggiungere i tuoi obiettivi professionali.
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                è individuare e proporre candidati altamente qualificati, persone il cui talento e impegno siano in linea con i valori e le esigenze specifiche
                delle imprese che serviamo. Grazie alla collaborazione con i nostri esperti consulenti, adottiamo un processo di selezione accurato e
                personalizzato. Sia che si tratti di tirocinanti, professionisti con esperienza, o figure dirigenziali, lavoriamo per garantire il miglior
                incontro tra candidati e aziende.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                Confartigianato Imprese è impegnata nell'ascolto attivo delle esigenze del territorio e offre soluzioni per chi cerca un primo inserimento, per
                chi è in mobilità o per chi è alla ricerca di nuove sfide. Con passione e professionalità, ci dedichiamo a costruire carriere e a rafforzare le
                aziende, contribuendo allo sviluppo economico e sociale della nostra comunità.
              </Typography>
            </Grid>
            <Grid size={12}>
              <Typography color="white.main" variant="h3">
                Scopri come possiamo aiutarti a raggiungere i tuoi obiettivi professionali!
              </Typography>
            </Grid>
          </>
        )}

        <Grid size={12} sx={{ display: "flex", justifyContent: "center" }} mb={10} mt={10}>
          <Typography color="white.main" variant="h1">
            Voi saperne di più?
          </Typography>
          <JobErButton size="large" variant="contained" color="white" onClick={() => navigate("/Vetrina/Registrati")} sx={{ ml: 2 }}>
            Contattaci
          </JobErButton>
        </Grid>
      </Grid>
      <FooterBB />
    </>
  );
}

export default ChiSiamo;
