import { JobErToast } from "components/JobErToast";

export async function asyncRicercaComuni(value) {
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");
    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaComuni/" + replaced, { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        let arrComuni = [];
        response.map((comune) =>
          arrComuni.push({
            value: comune.codiceComune,
            label: comune.des + " - " + comune.prov,
            prov: comune.prov,
          })
        );
        return arrComuni;
      })
      .catch((e) => {
        console.error("Si è verificato un errore");
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore" });
      });
    return data;
  } else {
    return null;
  }
}

export async function asyncRicercaComuni2(value, prov) {
  console.log(value);
  console.log(prov);
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");

    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaComuni/" + replaced, { method: "GET" })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          JobErToast.fire({ icon: "error", title: "Si è verificato un errore" });
          return null;
        }
      })
      .then((response2) => {
        let arrComuni = [];

        if (Array.isArray(prov)) {
          arrComuni = response2
            .filter((comune) => prov.includes(comune.prov)) // Check if `comune.prov` is in the array `prov`
            .map((comune) => ({
              value: comune.codiceComune,
              label: `${comune.des} - ${comune.prov}`,
            }));
        } else if (prov) {
          arrComuni = response2
            .filter((comune) => comune.prov === prov)
            .map((comune) => ({
              value: comune.codiceComune,
              label: `${comune.des} - ${comune.prov}`,
            }));
        } else {
          arrComuni = response2.map((comune) => ({
            value: comune.codiceComune,
            label: `${comune.des} - ${comune.prov}`,
          }));
        }

        return arrComuni;
      })
      .catch((e) => {
        console.error("Si è verificato un errore");
        JobErToast.fire({ icon: "error", title: "Si è verificato un errore" });
      });
    return data;
  } else {
    return null;
  }
}

export async function asyncRicercaSettore(value) {
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");
    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaSettori/" + replaced, { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        let arrSettori = [];
        response.map((comune) =>
          arrSettori.push({
            value: comune.codice,
            label: comune.descrizione + " (Ateco: " + comune.codice + ")",
          })
        );
        return arrSettori;
      });
    return data;
  } else {
    return null;
  }
}

export async function ricercaConfartigiananto(setListaConfartigianato) {
  fetch(process.env.REACT_APP_URL + "/utils/ricercaConfartigiananto", { method: "GET" })
    .then((response) => response.json())
    .then((response) => {
      let arrSettori = [];
      response.map((comune) =>
        arrSettori.push({
          value: comune.prov,
          label: comune.descrizione,
        })
      );
      setListaConfartigianato(arrSettori);
    });
}

export async function asyncRicercaProvince(value) {
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");
    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaProvince/" + replaced, { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        let arrProvince = [];
        response.map((prov) =>
          arrProvince.push({
            value: prov.codice,
            label: prov.descrizione + " ( " + prov.codice + " ) ",
          })
        );
        return arrProvince;
      });
    return data;
  } else {
    return null;
  }
}

export function optionsLavoroCodifiche(setOptionRuoli) {
  fetch(process.env.REACT_APP_URL + "/utils/optionsLavoroCodifiche", { method: "GET" })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return null;
    })
    .then((response) => {
      if (response) {
        const arrRuoli = response.map((mansione) => ({
          value: mansione.uuid,
          label: mansione.descrizione,
        }));
        setOptionRuoli(arrRuoli);
      }
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    });
}

export async function asyncRicercaRuoli(value) {
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");
    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaRuoli/" + replaced, { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        let arrRuoli = [];
        response.map((mansione) =>
          arrRuoli.push({
            value: mansione.tipologia + "_" + mansione.gruppo,
            label: mansione.descrizione,
            uuid: mansione.uuid,
            gruppo: mansione.gruppo,
          })
        );
        return arrRuoli;
      });
    return data;
  } else {
    return null;
  }
}

export async function asyncRicercaProgrammi(value) {
  if (value.length > 3) {
    let replaced = value.split(" ").join("{space}");
    replaced = value.split("'").join("{ap}");
    const data = fetch(process.env.REACT_APP_URL + "/utils/asyncListaProgrammi/" + replaced, { method: "GET" })
      .then((response) => response.json())
      .then((response) => {
        let arrProgrammi = [];
        response.map((programma) =>
          arrProgrammi.push({
            value: programma.uuid,
            label: programma.descrizione,
          })
        );
        return arrProgrammi;
      });
    return data;
  } else {
    return null;
  }
}
