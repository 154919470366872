import colors from "assets/palette/colorsPalette1";

// BizBuddy Dashboard 2 MUI Helper Functions
import boxShadow from "assets/theme-functions/boxShadow";

const { black, white, info, tabs, background, text, primary } = colors;

const boxShadows = {
  xs: boxShadow([0, 2], [9, -5], background.contrast, 0.15),
  sm: boxShadow([0, 5], [10, 0], background.contrast, 0.12),
  md: `${boxShadow([0, 4], [6, -1], background.contrast, 0.12)}, ${boxShadow([0, 2], [4, -1], background.contrast, 0.07)}`,
  lg: `${boxShadow([0, 8], [26, -4], background.contrast, 0.15)}, ${boxShadow([0, 8], [9, -5], background.contrast, 0.06)}`,
  xl: boxShadow([0, 23], [45, -11], background.contrast, 0.25),
  xxl: boxShadow([0, 20], [27, 0], background.contrast, 0.05),
  inset: boxShadow([0, 1], [2, 0], background.contrast, 0.075, "inset"),
  navbarBoxShadow: `${boxShadow([0, 0], [1, 1], background.primary, 0.9, "inset")}, ${boxShadow([0, 20], [27, 0], background.contrast, 0.05)}`,
  cardBoxShadow: `${boxShadow([0, 0], [16, 0], text.primary, 0.075)}`,
  buttonBoxShadow: {
    main: `${boxShadow([0, 4], [6, 0], background.contrast, 0.1)}, ${boxShadow([0, 1], [3, 0], background.contrast, 0.08)}`,
    stateOf: `${boxShadow([0, 7], [14, 0], background.contrast, 0.1)}, ${boxShadow([0, 3], [6, 0], background.contrast, 0.08)}`,
    stateOfNotHover: boxShadow([0, 0], [0, 3.2], info.main, 0.5),
  },
  inputBoxShadow: `${boxShadow([0, 3], [9, 0], info.main, 0)}, ${boxShadow([3, 4], [8, 0], info.main, 0.1)}`,
  sliderBoxShadow: {
    thumb: boxShadow([0, 1], [13, 0], background.contrast, 0.2),
  },
};

export default boxShadows;
