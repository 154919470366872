import React, { useEffect, useState } from "react";
import { Grid2 as Grid, Typography, useTheme } from "@mui/material";
import { disponibilitaTipologiaAnnunci } from "pages/portale_aziende/utils/utilsAnnunci";
import { useJobErController } from "context";
import JobErAutocomplete from "components/JobErAutocomplete";

function AnnuncioStep0(props) {
  const theme = useTheme();
  const [controller, dispatch] = useJobErController();
  const { authUser } = controller;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (authUser?.codConfartigianato && options.length === 0) {
      disponibilitaTipologiaAnnunci(authUser, setOptions);
    }
  }, [authUser]);

  return (
    <Grid container mt={2}>
      <Grid size={12}>
        <Grid container justifyContent="center" mt={0} spacing={2} p={2}>
          <Grid size={{ xs: 12, md: 7 }} textAlign="left">
            <JobErAutocomplete
              size="small"
              label="Modalità ricerca *"
              value={
                props.dati?.tipoRicerca && typeof props.dati.tipoRicerca === "object"
                  ? props.dati.tipoRicerca
                  : props.dati?.tipoRicerca && typeof props.dati.tipoRicerca === "string"
                  ? {
                      label: props.dati.tipoRicerca,
                      value: props.dati.tipoRicerca,
                    }
                  : null
              }
              options={options}
              onChange={(event, newValue) => {
                props.onSetDati("tipoRicerca", newValue);
              }}
            />
          </Grid>

          {options.map((obj, index) => (
            <React.Fragment key={index}>
              {obj.value === "attiva" && (
                <Grid size={{ xs: 12, md: 6 }} textAlign="left">
                  <Typography color={theme.palette.success.main} fontWeight="bold">
                    Ricerca attiva:
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    Gestiremo noi l'annuncio. Faremo in modo di presentarti i migliori candidati. Il costo sarà per assunzione.
                    <ul>
                      <li> Sarà {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? "Lapam" : "Job Confartigianato"} a gestire l'annuncio </li>
                      <li> Le candidature saranno filtrate in base ai criteri inseriti nell'annuncio di lavoro </li>
                      <li> Le informazioni anagrafiche dei candidati proposti non saranno visibili fino all'eventuale assunzione </li>
                      <li> Il costo per la singola assunzione è pari al 10% della RAL del candidato assunto </li>
                      <li>
                        La gestione dell'iter della candidatura sarà gestito da{" "}
                        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? "Lapam" : "Job Confartigianato"}{" "}
                      </li>
                      <li> Nessun costo nel caso in cui i candidati presentati non vengano assunti dall'azienda </li>
                    </ul>
                  </Typography>
                </Grid>
              )}
              {obj.value === "passiva" && (
                <Grid size={{ xs: 12, md: 6 }} textAlign="left">
                  <Typography color={theme.palette.error.main} fontWeight="bold">
                    Ricerca Passiva:
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    I candidati iscritti in piattaforma potranno candidarsi liberamente al seguente annuncio direttamente dalla loro area riservata.
                    <ul>
                      <li> Gestione autonoma dell'annuncio </li>
                      <li> Possibilità di visualizzare tutte le informazioni della persona dopo che si è candidata all'annuncio </li>
                      <li> Nessun costo nel caso in cui si decida di assumere un candidato sbloccato </li>
                      <li> Possibilità di gestire l'iter di candidatura dentro o fuori la piattaforma </li>
                    </ul>
                  </Typography>
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default AnnuncioStep0;
