import React from "react";
import "../../css/App.css";
import "../../css/JobEr.css";
import { Grid2 as Grid, Typography } from "@mui/material";
import { ReactComponent as BB_LONG_ER } from "../../immagini/JOB_LOGO_CONFER_WHITE.svg";
import { ReactComponent as BB_LONG_MORE } from "../../immagini/Logo_MylapamJob_WHITE.svg";
import { useNavigate } from "react-router-dom";
import privacy_mylapamjob from "utils/privacy_mylapamjob.pdf";

function FooterBB() {
  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = privacy_mylapamjob;
    link.download = "privacy.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      sx={{
        backgroundColor: "primary.light",
        pl: { xs: 2, md: 15 },
        pr: { xs: 2, md: 15 },
      }}
    >
      <Grid size={{ xs: 12, md: 6 }} style={{ textAlign: "left" }}>
        {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
          <BB_LONG_MORE
            fill={"white"}
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xl bb_menu"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/Vetrina/Home")}
          ></BB_LONG_MORE>
        ) : (
          <BB_LONG_ER
            fill={"white"}
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_xl bb_menu"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/Vetrina/Home")}
          ></BB_LONG_ER>
        )}
      </Grid>
      <Grid size={{ xs: 6, md: 6 }} alignSelf={"center"}>
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <Grid size={4} mt={1}>
              <Typography component="p" color={"white"} sx={{ textAlign: "right" }}>
                <b>
                  <a style={{ color: "inherit", textDecoration: "none" }} href="https://www.lapam.eu/contatti/" target="_blank" rel="noopener noreferrer">
                    Contatta la sede di riferimento
                  </a>
                </b>
              </Typography>
            </Grid>
          ) : null}
          <Grid
            size={4}
            mt={1}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography component="p" color={"white"} onClick={handleDownload} sx={{ textAlign: "right" }}>
              <b>Privacy Policy e Trattamento Dati</b>
            </Typography>
          </Grid>

          {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
            <Grid size={2} mt={1}>
              <Typography component="p" color={"white"} sx={{ textAlign: "right" }}>
                <b>
                  <a style={{ color: "inherit", textDecoration: "none" }} href="https://www.lapam.eu/" target="_blank" rel="noopener noreferrer">
                    by Lapam
                  </a>
                </b>
              </Typography>
            </Grid>
          ) : (
            <Grid size={4} mt={1}>
              <Typography component="p" color={"white"} sx={{ textAlign: "right" }}>
                <b>
                  <a style={{ color: "inherit", textDecoration: "none" }} href="https://www.confartigianato.it/" target="_blank" rel="noopener noreferrer">
                    by Confartigianato Imprese
                  </a>
                </b>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FooterBB;
