import React, { useEffect } from "react";
import MenuJOB from "../../components/Vetrina/AppBarJob.js";
import FooterBB from "../../components/Vetrina/Footer.js";
import "../../css/App.css";
import "../../css/JobEr.css";
import "../../css/JobMORE.css";
import { Button, Divider, Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import FactoryIcon from "@mui/icons-material/Factory";
import { JobErToast } from "components/JobErToast";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import imgConfartigianato from "immagini/homeEr.webp";
import imgLapam from "immagini/homeMore.webp";

function Home(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const params = [];
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    if (params.tipo && params.token && params.tipo === "azienda") {
      fetch(process.env.REACT_APP_URL + "/user/confermaRegistrazioneAzienda/" + params.token, {
        method: "GET",
      })
        .then((res) => {
          if (!res.ok) {
            JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
            return null;
          }
          return res.json();
        })
        .then(
          (result) => {
            if (result) {
              JobErToast.fire({ icon: "success", title: "Utente attivato con successo!" });
            } else {
              JobErToast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
            }
          },
          (error) => {
            JobErToast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
          }
        );
    } else if (params.tipo && params.token && params.tipo === "persona") {
      fetch(process.env.REACT_APP_URL + "/user/confermaRegistrazionePersona/" + params.token, {
        method: "GET",
      })
        .then((res) => {
          if (!res.ok) {
            JobErToast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
            return null;
          }
          return res.json();
        })
        .then(
          (result) => {
            if (result) {
              JobErToast.fire({ icon: "success", title: "Utente attivato con successo!" });
            } else {
              JobErToast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
            }
          },
          (error) => {
            JobErToast.fire({ icon: "error", title: "Si è verificato un errore nell'attivazione dell'utente" });
          }
        );
    }
  }, []);

  return (
    <>
      <MenuJOB bgcolor="primary.main" color="white.main" activePage={"Home"} onUserLogin={(user) => props.onUserLogin(user)}></MenuJOB>
      {/* Inizio seconda parte della pagina */}
      <Grid spacing={3} container backgroundColor="primary.main" sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 } }} pb={10}>
        <Grid size={12}>
          <Typography variant="h1" color="white.main" align="center">
            <i>Con noi</i> chi cerca <b>trova</b>
          </Typography>
        </Grid>
        <Grid
          pb={10}
          size={12}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
          }}
        >
          <img
            alt="azienda"
            src={process.env.REACT_APP_AMBIENTE === "modena-reggio" ? imgLapam : imgConfartigianato}
            style={{
              height: "50vh",
            }}
          />
        </Grid>
        <Grid size={12} sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}>
          <img
            alt="azienda"
            src={process.env.REACT_APP_AMBIENTE === "modena-reggio" ? imgLapam : imgConfartigianato}
            style={{
              height: "43vh",
            }}
          />
        </Grid>

        <Grid
          size={{ xs: 12, md: 5.5 }}
          sx={{
            display: "flex",
            textAlign: { md: "right" },
            alignItems: "center",
            justifyContent: { md: "end", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              navigate("/Vetrina/Persone");
            }}
          >
            <Typography color="white.main" variant="h2" fontWeight={"bold"}>
              Per i candidati
            </Typography>
            <PeopleAltIcon sx={{ color: "white.main", fontSize: 90, ml: 2 }} />
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          size={{ xs: 0, md: 1 }}
        >
          <Divider
            orientation="vertical"
            sx={{
              borderColor: "white.main",
            }}
          />
        </Grid>
        <Grid
          size={{ xs: 12, md: 5.5 }}
          sx={{
            display: "flex",
            textAlign: { md: "right" },
            alignItems: "center",
            justifyContent: { md: "start", xs: "center" },
          }}
        >
          <Button
            onClick={() => {
              navigate("/Vetrina/Aziende");
            }}
          >
            <FactoryIcon sx={{ color: "white.main", fontSize: 90, mr: 2 }} />
            <Typography color="white.main" variant="h2" fontWeight={"bold"}>
              Per le Aziende
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <FooterBB />
    </>
  );
}

export default Home;
