import React, { useEffect, useState } from "react";
import { Autocomplete, Grid2 as Grid, TextField, Typography } from "@mui/material";
import JobErButton from "components/JobErButton";
import { asyncRicercaComuni2 } from "utils/utilsRicerca";
import { cercaAnnunciVetrina } from "./utils/UtilsVetrina";
import { ProvinceER } from "utils/ProvinceER";
import CardAnnunciPersone from "components/Vetrina/PerLePersone/CardAnnunciPersone";
import { optionsLavoroCodifiche } from "utils/utilsRicerca";

function RicercaLavoriPersona({ annunci, setAnnunci }) {
  const [formData, setFormData] = useState({ provincia: [], comune: [], professione: [] });
  const [pendingFilter, setPendingFilter] = useState(false);
  const [messaggio, setMessaggio] = useState("");
  const [optionComuni, setOptionComuni] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [optionRuoli, setOptionRuoli] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_AMBIENTE === "modena-reggio") {
      setListaProvince([
        { label: "Modena", value: "MO" },
        { label: "Reggio Emilia", value: "RE" },
      ]);
    } else {
      setListaProvince(ProvinceER);
    }

    optionsLavoroCodifiche(setOptionRuoli);
  }, []);

  return (
    <>
      <Grid size={12}>
        <Typography variant="h1" fontWeight={"bold"} textAlign={"center"} color={"white.main"}>
          Che lavoro stai cercando?
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 3.5 }}>
        <Autocomplete
          color="white"
          multiple
          size="small"
          options={optionRuoli}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} color="white" label="Ricerca Professione" />}
          value={formData.professione}
          onChange={(event, newValue) => {
            setFormData({ ...formData, professione: newValue, comune: [] });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3.5 }}>
        <Autocomplete
          color="white"
          multiple
          size="small"
          options={listaProvince}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} color="white" label="Ricerca Provincia" />}
          value={formData.provincia}
          onChange={(event, newValue) => {
            setFormData({ ...formData, provincia: newValue, comune: [] });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 3.5 }}>
        <Autocomplete
          multiple
          color="white"
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} color="white" label="Ricerca Comune" />}
          onClose={() => {
            setOptionComuni([]);
          }}
          loading={loading2}
          size="small"
          value={formData?.comune}
          onInput={(e) => {
            let inputValue = e.target.value;

            if (inputValue?.length >= 3) {
              setLoading2(true);
              asyncRicercaComuni2(inputValue, formData?.provincia?.value).then((response) => {
                if (response) {
                  setOptionComuni(response);
                  setLoading2(false);
                }
              });
            }
          }}
          options={optionComuni}
          onChange={(data, newValue) => {
            setFormData({ ...formData, comune: newValue });
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 1.5 }}>
        <JobErButton
          color="white"
          size="large"
          fullWidth={true}
          isLoading={pendingFilter}
          onClick={() => {
            cercaAnnunciVetrina(formData, setAnnunci, setPendingFilter, setMessaggio);
          }}
        >
          Cerca
        </JobErButton>
      </Grid>
      <Grid
        container
        backgroundColor={"primary.main"}
        sx={{
          display: { md: "flex" },
          width: "100%",
          pl: { xs: 2, md: 15 },
          pr: { xs: 2, md: 15 },
          pt: { xs: 4, md: 8 },
        }}
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {annunci.length > 0 ? (
          <>
            {annunci.map((annuncio, index) => (
              <Grid size={{ xs: 12, md: 3 }}>
                <CardAnnunciPersone
                  gruppoLavoro={annuncio?.gruppo || null}
                  mansione={annuncio?.descrizione || null}
                  titolo={annuncio?.titoloAnnuncio || null}
                  descrizioneLavoro={annuncio?.descrizioneLavoro || null}
                  comune={annuncio?.comune || null}
                  posizioniAperte={annuncio?.posizioniAperte || null}
                />
              </Grid>
            ))}
          </>
        ) : (
          <Grid size={12} pb={{ xs: 4, md: 8 }}>
            <Typography
              variant="h4"
              color={"white.main"}
              sx={{
                textAlign: "center!important",
              }}
            >
              {messaggio}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
export default RicercaLavoriPersona;
