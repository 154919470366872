import typography from "assets/base/typography";
import borders from "assets/base/borders";
import pxToRem from "assets/theme-functions/pxToRem";

const { size } = typography;
const { borderRadius } = borders;

const autocomplete = {
  defaultProps: {
    color: "primary",
  },
  styleOverrides: {
    input: ({ ownerState, theme }) => ({
      padding: `9px 13px`,
    }),
    inputRoot: ({ ownerState, theme }) => ({
      paddingTop: `0px`,
      paddingBottom: `0px`,
    }),
    popper: ({ ownerState, theme }) => ({
      boxShadow: `0px 2px 10px ${theme.palette.background.contrast}22`,
      padding: pxToRem(8),
      fontSize: `16px !important`,
      color: theme.palette.text.primary,
      textAlign: "left",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
    }),

    paper: ({ ownerState, theme }) => ({
      boxShadow: "none",
      backgroundColor: theme.palette.background.paper,
    }),

    option: ({ ownerState, theme }) => ({
      padding: `9px 13px`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      color: theme.palette.text.primary,
      transition: "background-color 300ms ease, color 300ms ease",

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: theme.palette.primary.main + "33",
        color: theme.palette.background.contrast,
      },

      '&[aria-selected="true"]': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
    }),

    noOptions: ({ ownerState, theme }) => ({
      fontSize: size.sm,
      color: theme.palette.text.primary,
    }),

    groupLabel: ({ ownerState, theme }) => ({
      color: theme.palette.background.contrast,
    }),

    loading: ({ ownerState, theme }) => ({
      fontSize: size.sm,
      color: theme.palette.text.primary,
    }),

    tag: ({ ownerState, theme }) => ({
      display: "flex",
      alignItems: "center",
      height: "auto",
      padding: pxToRem(4),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light,

      "& .MuiChip-deleteIcon": {
        color: theme.palette.error.main,
      },
      "& .MuiChip-label": {
        lineHeight: 1.2,
        padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
      },

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        color: theme.palette.background.primary,
        marginRight: 0,
      },
    }),

    popupIndicator: ({ ownerState, theme }) => ({
      color: ownerState ? theme.palette[ownerState.color].main : "#ffffff00",
    }),

    clearIndicator: ({ ownerState, theme }) => ({
      color: theme.palette.error.main,
    }),
  },
};

export default autocomplete;
