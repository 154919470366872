import { Grid2 as Grid, Typography } from "@mui/material";
import MenuJOB from "../../components/Vetrina/AppBarJob.js";
import FooterBB from "components/Vetrina/Footer.js";
import JobErAutocomplete from "components/JobErAutocomplete.js";
import { useState } from "react";
import RichiestaInformazioniCandidato from "components/Vetrina/PerLePersone/RichiestaInformazioni.js";
import RichiestaInformazioniAzienda from "components/Vetrina/PerLeAziende/RichiestaInformazioni.js";

function Contattaci(props) {
  const options = [
    { label: "azienda", value: "azienda" },
    { label: "candidato", value: "candidato" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <MenuJOB bgcolor={"primary.main"} color={"white.main"} activePage={"Home"} onUserLogin={(user) => props.onUserLogin(user)}></MenuJOB>
      <Grid spacing={3} container backgroundColor="primary.main" sx={{ pl: { xs: 2, md: 15 }, pr: { xs: 2, md: 15 }, pb: { xs: !selectedOption ? 30 : 0 } }}>
        <Grid size={12}>
          <Typography color="white.main" variant="h1" fontWeight={"bold"}>
            Hai bisogno di aiuto?
          </Typography>
          <Typography color="white.main" variant="h3">
            Il nostro team di supporto è a tua disposizione per qualsiasi domanda o problema.
          </Typography>
        </Grid>
        <Grid size={12}>
          <Typography color="white.main" variant="h3" fontWeight={"bold"}>
            Compila il modulo di contatto:
          </Typography>
          <Typography color="white.main" variant="h3">
            rispondi a poche semplici domande e un membro del nostro team ti risponderà al più presto.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 3 }} pb={{ xs: 2, md: 5 }}>
          <JobErAutocomplete
            color="white"
            size="small"
            label="Natura del richiedente"
            value={selectedOption}
            options={options}
            onChange={(event, newValue) => {
              setSelectedOption(newValue ? newValue : null);
            }}
          />
        </Grid>
        <Grid size={{ xs: 12 }} pb={{ xs: 4, md: 8 }}>
          {selectedOption?.value === "azienda" && <RichiestaInformazioniAzienda />}
          {selectedOption?.value === "candidato" && <RichiestaInformazioniCandidato />}
        </Grid>
      </Grid>
      <FooterBB />
    </>
  );
}

export default Contattaci;
